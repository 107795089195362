<template>
    <div class="w-full h-full module flex py-0">
        <projects-management class="w-1/3" :billing="true" />
        <separator :direction="'vertical'"/>
        <transition v-if="$route.params.projectId" name="fade" enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut" mode="out-in">
            <SingleProject />
        </transition>
        <transition name="fade" enter-active-class="animated fadeIn"
                    leave-active-class="animated fadeOut" mode="out-in">
            <AllProjects />
        </transition>
    </div>
</template>

<script>

    const ProjectsManagement = () => import('@/components/Desktop/ProjectsManagement')
    const AllProjects = () => import("@/components/Desktop/ProjectsManagement/AllProjects");
    const SingleProject = () => import("@/components/Desktop/ProjectsManagement/SingleProject");

    export default {
        components: {
            SingleProject,
            AllProjects,
            ProjectsManagement
        },
    }

</script>